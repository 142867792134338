<template>
    <div id="home" class="page-content">
        <div class="banner">
            <img
                data-src="~/assets/images/tree-bg.jpg?webp"
                alt="Metropolitan Arborist"
                class="banner-img desktop lazyload"
                type="image/webp"
            />
            <img
                data-src="~/assets/images/tree-bg-mobile.jpg?webp"
                alt="Metropolitan Arborist"
                class="banner-img mobile lazyload"
                type="image/webp"
            />

            <div class="banner-text">
                <div class="container">
                    <div class="text-inner">
                        <div v-html="page.content"></div>

                        <el-button type="primary" plain @click="scrollDown"
                            >What Do We Offer</el-button
                        >

                        <p class="call-us">
                            <i class="el-icon-mobile-phone" /> Call Us
                            <a href="tel:+61412419607">
                                +61 412 419 607
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div class="banner-credits">
                <div class="container">
                    <a
                        href="https://unsplash.com/@toddquackenbush?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
                        target="_blank"
                        class="unsplash-credits"
                        rel="noreferrer"
                    >
                        <span class="unsplash-logo">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 32 32"
                            >
                                <title>unsplash-logo</title>
                                <path
                                    d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"
                                ></path>
                            </svg>
                        </span>
                        <span class="unsplash-text">
                            <p class="text1">Todd Quackenbush</p>
                            <p class="text2">@toddquackenbush</p>
                        </span>
                    </a>
                </div>
            </div>
        </div>

        <div id="services" class="container">
            <el-row
                v-for="node in threeColumnsNode"
                :id="node.slug"
                :key="node.id"
                :gutter="0"
                class="services"
                type="flex"
            >
                <el-col
                    v-for="(item, index) in node.node_structure.items"
                    :key="index"
                    :xs="24"
                    :sm="24"
                >
                    <div class="service">
                        <img
                            v-if="item.slug === 'pruning'"
                            data-src="~/assets/images/pruning.svg"
                            alt="Pruning"
                            class="lazyload"
                        />
                        <img
                            v-else-if="item.slug === 'planting'"
                            data-src="~/assets/images/planting.svg"
                            alt="Planting"
                            class="lazyload"
                        />
                        <img
                            v-else-if="item.slug === 'cabling'"
                            data-src="~/assets/images/cabling.svg"
                            alt="Cabling"
                            class="lazyload"
                        />
                        <img
                            v-else-if="item.slug === 'pest'"
                            data-src="~/assets/images/pest.svg"
                            alt="Pest"
                            class="lazyload"
                        />
                        <img
                            v-else-if="item.slug === 'leaf'"
                            data-src="~/assets/images/leaf.svg"
                            alt="Leaf"
                            class="lazyload"
                        />
                        <img
                            v-else-if="item.slug === 'vertical-garden'"
                            data-src="~/assets/images/vertical-garden.svg"
                            alt="Vertical Garden lazyload"
                            class="lazyload"
                        />
                        <h2>
                            {{ item.title }}
                        </h2>
                        <span class="separator"></span>
                        <div class="text" v-html="item.description"></div>
                    </div>
                </el-col>
            </el-row>

            <el-row
                v-for="node in twoColumnsNode"
                :id="node.slug"
                :key="node.id"
                :gutter="20"
            >
                <div
                    v-if="node.slug === 'proudly-arborist'"
                    class="marginT backImage"
                >
                    <el-col
                        v-for="(item, index) in node.node_structure.items"
                        :key="index"
                        :xs="24"
                        :sm="24"
                        :md="12"
                    >
                        <h2 :style="{ marginTop: '0' }">
                            {{ item.title }}
                        </h2>
                        <div v-html="item.description" />
                    </el-col>
                </div>
            </el-row>

            <div class="testimonials">
                <h2 :style="{ textAlign: 'center' }" class="marginT">
                    What people say about us
                </h2>
                <div v-for="node in accordions" :key="node.id">
                    <div v-if="node.slug === 'testimonials'">
                        <no-ssr placeholder="Loading...">
                            <agile
                                :dots="false"
                                :infinite="true"
                                :autoplay="true"
                                :autoplay-speed="8000"
                                :fade="true"
                                :nav-buttons="false"
                                :center-mode="true"
                                :speed="1000"
                                class="marginT"
                            >
                                <div
                                    v-for="(item, index) in node.node_structure
                                        .items"
                                    :key="index"
                                    class="slide"
                                >
                                    <h2 v-html="item.content"></h2>
                                    <img
                                        :src="avatarUrl + item.id"
                                        class="avatar lazyload"
                                        alt="avatar"
                                    />
                                    <p>
                                        #
                                        {{ item.title }}
                                    </p>
                                </div>
                            </agile>
                        </no-ssr>
                    </div>
                </div>
            </div>

            <div class="featured-image marginT">
                <div
                    :style="{
                        backgroundImage: 'url(' + appUrl + page.image + ')'
                    }"
                    class="banner-img lazyload"
                >
                    <div class="wrapper-sm">
                        <h1>Choose Metropolitan Arborist</h1>
                        <p>
                            We guarantee the best final result with our
                            hassle-free service.
                        </p>
                        <a
                            href="/contact"
                            class="el-button el-button--primary is-plain"
                        >
                            Contact Us
                        </a>
                    </div>
                </div>

                <div class="banner-credits">
                    <div class="container">
                        <a
                            href="https://unsplash.com/@gabrielj_photography?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
                            target="_blank"
                            class="unsplash-credits"
                            rel="noreferrer"
                        >
                            <span class="unsplash-logo">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                >
                                    <title>unsplash-logo</title>
                                    <path
                                        d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"
                                    ></path>
                                </svg>
                            </span>
                            <span class="unsplash-text">
                                <p class="text1">Gabriel Jimenez</p>
                            </span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="marginT">
                <h2 :style="{ textAlign: 'center' }">
                    Metropolitan Arborists Posts
                </h2>
                <el-row
                    v-if="page_contents.posts && page_contents.posts.data"
                    type="flex"
                    class="blog-rows"
                >
                    <el-col
                        v-for="post of page_contents.posts.data"
                        :key="post.slug"
                        :xs="24"
                        :sm="24"
                        :md="24"
                    >
                        <nuxt-link :to="`/blog/${post.slug}`">
                            <el-card>
                                <div
                                    :style="{
                                        backgroundImage:
                                            'url(' + appUrl + post.image + ')'
                                    }"
                                    :alt="post.title"
                                    class="post-img lazyload"
                                />
                                <span>{{ post.title }}</span>
                                <div>
                                    <p v-html="post.extract" />
                                </div>
                            </el-card>
                        </nuxt-link>
                    </el-col>
                </el-row>
                <div
                    class="container"
                    :style="{ textAlign: 'center', marginTop: '30px' }"
                >
                    <a
                        href="/blog"
                        class="el-button el-button--primary is-plain"
                    >
                        Browse All Posts
                    </a>
                </div>
            </div>

            <div
                v-for="node in accordions"
                :key="node.id"
                class="marginT"
                :style="{ textAlign: 'center' }"
            >
                <div v-if="node.slug === 'tree-facts'">
                    <div class="facts marginT">
                        <div
                            v-for="(item, index) in node.node_structure.items"
                            :key="index"
                            class="fact"
                        >
                            <h3>{{ item.title }}</h3>
                            <div class="" v-html="item.content" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    async asyncData({ app, store, error }) {
        await store
            .dispatch('general/getPage', app.context.route.name)
            .catch((e) => {
                error({
                    statusCode: e.response.status,
                    message: 'Page not found'
                })
            })
    },
    head() {
        return {
            title: this.page.title,
            meta: [
                {
                    hid: 'title',
                    name: 'title',
                    content: this.page.meta_title
                },
                {
                    hid: 'description',
                    name: 'description',
                    content: this.page.meta_description
                }
            ]
        }
    },

    computed: {
        ...mapGetters({
            page: 'general/page',
            page_contents: 'general/page_contents'
        }),

        threeColumnsNode() {
            return this.page.nodes.filter(function(el) {
                return el.columns === 3
            })
        },
        twoColumnsNode() {
            return this.page.nodes.filter(function(el) {
                return el.columns === 2
            })
        },
        accordions() {
            return this.page.nodes.filter(function(el) {
                return el.node_structure.type_section === 'accordion'
            })
        },
        appUrl() {
            return process.env.appUrl
        },
        avatarUrl() {
            return 'https://picsum.photos/200/300?random='
        }
    },

    methods: {
        scrollDown() {
            const services = this.$el.querySelector('#services')
            window.scrollTo({
                top: services.offsetTop - 65,
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-content {
    padding-top: 0;
}

.banner {
    position: relative;

    .banner-img {
        display: block;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        opacity: 0.65;

        &.mobile {
            display: none;
        }
    }

    .banner-text {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 34%;
        transform: translateX(-50%);
    }

    .text-inner {
        max-width: 395px;

        h1 {
            margin: 0.5em 0;
        }
    }

    .el-button {
        margin-top: 0.5em;
        margin-right: 0.5em;
    }

    .call-us {
        font-size: 1.35em;
        font-family: 'Montserrat', sans-serif;
    }

    .dot-points {
        position: absolute;
        width: 100%;
        left: 50%;
        bottom: 0em;
        transform: translateX(-50%);

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
        }

        li {
            display: inline-block;
            font-size: 1.35em;
            font-family: 'Montserrat', sans-serif;
            margin-right: 2em;
        }

        i {
            font-weight: 600;
            margin-right: 0.5em;
        }
    }
}

.services {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    display: grid;

    .service {
        display: inline-block;
        width: 100%;
        padding: 3em 1.5em 2.5em 1.5em;
        text-align: center;
        border-radius: 30px;

        &:hover {
            i {
                transform: translateX(15%);
            }
        }
    }

    &:nth-of-type(1) {
        margin-top: 30px;
    }

    h2 {
        margin-bottom: 0;
    }

    img {
        width: 95px;
        height: 95px;
    }

    i {
        font-size: 1.75em;
        transition: 0.2s ease-in-out;
    }
}

.separator {
    margin: 0 auto;
    margin-top: 20px;
}

.snapshots {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 321px;
    max-width: 460px;

    img {
        position: absolute;
        width: 260px;

        &:first-child {
            top: 0;
            left: 0;
        }

        &:nth-of-type(2) {
            top: 3em;
            right: 0;
        }
    }
}

.testimonials {
    .agile {
        text-align: center;
        border-radius: 13px;
        padding: 1.5em;
        margin-top: 1em;
        background-image: url('~assets/images/left-quote.svg');
        background-repeat: no-repeat;
        background-position: 0% 17%;
        background-size: 6%;

        .slide {
            h2 {
                font-size: 1.15em;
                line-height: 1.8;
            }

            p {
                letter-spacing: -0.4px;
                line-height: 1.8;
                font-size: 18px;
                color: $body-light;
            }

            .avatar {
                border-radius: 50%;
                overflow: hidden;
                height: 60px;
                width: 60px;
            }
        }
    }

    h4,
    h5 {
        margin: 0;
    }

    h4 {
        color: $secondary;
    }

    h5 {
        color: $primary;
    }
}

.flex-head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        font-size: 1.5em;
        text-decoration: underline;
    }
}

.featured-image {
    .wrapper-sm {
        max-width: 90%;
        margin: 0 auto;
        width: 600px;
        text-align: center;
        position: relative;
        top: 35%;

        h1 {
            color: #fff;
        }

        p {
            color: #fff;
            opacity: 0.8;
            font-size: 13px;
        }

        .el-button {
            margin-top: 20px;
        }
    }
}

.facts {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    display: grid;

    .fact {
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        background: #fff;
        padding: 10px;
    }
}

.local-btn {
    position: absolute;
    z-index: 1;
    right: -3.4rem;
    top: 1em;
    box-shadow: none;
    background: $floral-white;
    border: none;
    color: $body;
    white-space: normal;
    text-align: left;
    border-radius: 13px 0 0 13px;
    padding: 1em;
    -webkit-box-shadow: -3px 3px 0px 0px $primary;
    -moz-box-shadow: -3px 3px 0px 0px $primary;
    box-shadow: -3px 3px 0px 0px $primary;

    &:hover {
        -webkit-box-shadow: 3px -3px 0px 0px $primary;
        -moz-box-shadow: 3px -3px 0px 0px $primary;
        box-shadow: 3px -3px 0px 0px $primary;
    }

    .small {
        font-size: 0.7em;
        display: table;
        width: 195px;
        margin-top: 0.5em;
        opacity: 0.8;
        padding-left: 1.3em;
        line-height: 1.15em;
    }
}

.el-popover {
    p {
        font-size: 90%;
        margin-bottom: 0;
    }
}

.blog-rows {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    display: grid;

    span {
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        margin-bottom: 1em;
    }

    p {
        margin: 0;
        font-size: 90%;
        color: $body;
    }

    .el-col {
        margin-top: 2em;
    }
}

.post-img {
    display: block;
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center center;
    border-radius: 5px;
}

.el-card {
    height: 100%;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);

    &:hover {
        background-color: #e7f7ef;
        color: #1b5f34;
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    }
}

@media only screen and (min-width: 1439px) {
}

@media only screen and (max-width: 1199px) {
    .banner {
        .text-inner {
            max-width: 295px;
        }
    }
}

@media only screen and (max-width: 991px) {
    .banner {
        .banner-img {
            opacity: 0.35;

            &.desktop {
                display: none;
            }

            &.mobile {
                display: block;
            }
        }

        .banner-text {
            top: 67%;
            transform: translate(-50%, -50%);
        }

        .text-inner {
            margin: 0 auto;
            text-align: center;
        }

        .dot-points {
            text-align: center;
        }
    }

    .services {
        grid-template-columns: 1fr;

        .service {
            padding: 2em 0.5em 1.5em 0.5em;
        }

        &:nth-of-type(1) {
            margin-top: 50px;
        }
    }

    .facts {
        grid-template-columns: 1fr;
    }

    .local-btn {
        right: -1.4rem;
    }
}

@media only screen and (max-width: 767px) {
    .testimonials {
        .el-row,
        &.el-row {
            margin-left: -10px !important;
            margin-right: -10px !important;

            .el-col {
                padding-left: 10px !important;
                padding-right: 10px !important;
            }
        }
    }

    .services {
        .service {
            padding: 2em;
            border-bottom: 1px solid $linen;
        }
    }

    .snapshots {
        max-width: 370px;
        height: 301px;
        margin-top: 5em;

        img {
            width: 210px;

            &:nth-of-type(2) {
                top: 5em;
            }
        }
    }

    .local-btn {
        right: 0rem;
    }

    .blog-rows {
        grid-template-columns: repeat(1, 1fr);

        .el-card {
            width: 80%;
            margin: 0 auto;
        }
    }

    .flex-head {
        flex-direction: column;
        align-items: flex-start;

        h2 {
            margin: 0;
        }
    }

    .instagram {
        width: calc(100% + 0.5em);
        margin-left: -0.25em;
        margin-top: 1em;

        .post {
            flex: 1 1 calc(33.33% - 0.5em);
            margin: 0.25em;
        }
    }
}

@media only screen and (max-width: 665px) {
    .banner {
        .banner-text,
        .dot-points {
            position: initial;
            transform: none;
        }

        .dot-points {
            margin-top: 1.5em;

            li {
                margin: 0.5em 0.5em 0 0.5em;
            }
        }

        .banner-img {
            &.mobile {
                opacity: 0.5;
            }
        }

        .text-inner {
            margin-top: 1.5em;
        }

        .el-button {
            width: 100%;
            margin: 0.5em 0 0 0;
        }
    }
}
</style>
